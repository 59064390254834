/*
    Styles used for all the pages that use template setup_base.html or error_base.html
    which includes the login page and the setup pages
*/

img#logo {
    margin-top: 16px;
    margin-bottom: 16px;
}


.language_header{
    margin-top: 20px;
    margin-bottom: 10px;
}

ul.steps {position:absolute;right:30px;top:-50px;}
.steps li {float:left;color:#666;width:100px;text-align:center;background:transparent url(../images/progress-bar.png) top center no-repeat;padding-top:16px;}
.steps li.active {background-position:center -50px;font-weight:bold;}
.steps li.incomplete {background-position:center -100px;}
.steps li.first.complete {background-position:46px 0;}
.steps li.first.active {background-position:46px -50px ;}
.steps li.first.incomplete {background-position:46px -100px;}
.steps li.last.complete {background-position:-84px -50px ;}
.steps li.last.active {background-position:-84px -50px ;}
.steps li.last.incomplete {background-position:-84px -100px;}

.additional_info .widgets-district_selector ul.regions li {display:none;}
.additional_info .widgets-district_selector ul.regions li.region {display:block;}
.additional_info .widgets-district_selector img {display:none;}
.additional_info .label_district {min-width: 120px;}
.additional_info .controls { padding-left: 10px; }
.additional_info div.hr {margin:5px 0;border-top:1px solid #BBBBBB;}
.additional_info .node_name { line-height: 26px; }

.link_services a.added { cursor: default; }
a.setup_button {display:block;width:193px;height:31px;margin:0 auto;background:url("../images/new_ui/setup-button-blank.gif") top center no-repeat;text-align:center;color:#FFFFFF;padding-top:10px;overflow:hidden;-webkit-text-shadow:0 1px 1px #666;text-shadow:0 1px 1px #666;letter-spacing:1px;cursor:default;}
a.setup_button.enable {background-position:center -41px;cursor:pointer;}
a.setup_button:hover {color:#fff;}
.setup_network {text-align: center; margin-bottom: 40px;}

#setup-facebook .button,#setup-linkedin .button,#setup-twitter .button { margin-left: 27px;width: 200px; }
.link_services #signup { float: right;margin: 25px 0 25px 8px;min-width: 115px; }
.link_services .button-action-loader { float: right;margin-top: 32px; }

#finish_setup {margin-bottom: 30px;}
#progress_container {text-align: center; font-weight: bold; padding: 30px 0;}
#setup_finish {overflow: auto;}
#progress_container.finish-button-display { text-align: right; }
#finish { margin-left: 10px;margin-right: 125px; }

.setup_base .well {
    background-color: #e0e0e0;
    border:1px solid #bbbbbb;
    margin: 0 auto;
    max-width: 220px;
    text-align: center;
}

.questions a {text-decoration: none;color: #555655;}
.questions a:hover {text-decoration: underline;}
.questions .email {text-decoration: underline;color: #0064B1;}
.questions .hide {padding-left:15px;}
.qhead a {padding-left: 10px;}

#additional_info_submit { float:right;margin: 25px 0;min-width: 115px; }
.additional_info .button-action-loader { float: right;margin: 32px 8px 0 0; }

#payment_button { float:right;margin: 25px 0;min-width: 115px; }

.eula-accept { margin-top: 10px; }

.eula_text .eula_container { max-height: 400px; overflow: auto; }
.eula_text p { padding: 0; margin: 0; }

#accept_and_continue { margin: 20px 0; }

#welcome_get_started { margin: 20px 0; }

.invisible { visibility: hidden }

.notification_banner {
    font-size:14px;
    margin-bottom:26px;
    padding:10px;
    color:#555;
    background-color:#F1F2F2;
    overflow: hidden;
    word-wrap: break-word;
    margin: 10px 10px;
}

.notification_banner .remove_message {
    font-weight: bold;
    float: right;
}

.notification_banner .remove_message:hover {
    cursor: pointer;
}

div.notification_banner a {
    color: #0064B1;
}

.border_red {
    border:1px solid #DD3F0F;
}

.btn-social .hss-social-media-icons,  .btn-social .hss-ui-icons {
    margin-top: -6px;
}

#network_container {
    float: left;
}

.login_text {
    margin: 10px 0;
    padding: 10px;
}

.login_network {
    background-color: #dddddf;
    padding: 10px 22px 10px 22px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
}

.login_network .social-icon {
    margin-right: 10px;
}

.login_network_title {
    line-height: 20px;
    display: inline-block;
    margin: 0 10px;
}

.text_center {
    text-align: center;
}

#notice_red {
    color: #DD3F0F;
}

#try_login p {
    margin: 12px 5px;
}

#try_login h3 {
    margin: 0 5px;
}

#email_login_dialog {
    display: none;
}

.choose-language {
    width: 200px;
    margin-top: 32px;
    margin-bottom: 16px;
    float: right;
}

.language-list-column {
    float: left;
    width: 303px;
}

.language-list-section {
    padding: 18px 10px 12px;
}

.connect-accounts {
    margin-top: 15px;
    margin-bottom: 15px;
}

.account-explanation {
    margin-top: 10px;
    margin-bottom: 10px;
}

.account-explanation .question {
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 20px;
    font-size: 16px;
}
.welcome-body {
    margin-top: 15px;
}

.email-input {
    margin-top: 10px;
    margin-bottom: 15px;
    width: 400px;
    max-width: 100%;
}

.email_domains {
    color: #d9534f;
}

#update-email-btn {
    margin-top: 10px;
}

h2 a {
    color: #0064b1;
    text-decoration: none;
}

h1.signup-header {
    font-weight: bold;
}
